import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

export const PricingList = ({ listClasses, children }) => {
  const listRef = useRef(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const [subPriceHeight, setSubPriceHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      var currentTitleHeights = Array.from(
        listRef.current.querySelectorAll(".pricing-head .pricing-title")
      ).map((title) => {
        return title.offsetHeight;
      });
      setTitleHeight(Math.max(...currentTitleHeights));

      var currentSubPriceHeights = Array.from(
        listRef.current.querySelectorAll(".pricing-head .pricing-sub-price")
      ).map((title) => {
        return title.offsetHeight;
      });
      setSubPriceHeight(Math.max(...currentSubPriceHeights));
    }, 1);
  }, [titleHeight]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        listState: { titleHeight: titleHeight, subPriceHeight: subPriceHeight },
      });
    }
    return child;
  });

  return (
    <div ref={listRef} className={classNames("pricing-list", listClasses)}>
      {childrenWithProps}
    </div>
  );
};

export const PricingListItem = ({
  listState,
  title,
  price,
  subPrice,
  itemClasses,
  headClasses,
  bodyClasses,
  children,
}) => {
  return (
    <div className={classNames("pricing-item", itemClasses)}>
      <div className={classNames("pricing-head", headClasses)}>
        <h3
          className="pricing-title h2"
          style={{
            height:
              listState && listState.titleHeight
                ? `${listState.titleHeight}px`
                : "initial",
          }}
        >
          {title}
        </h3>
        <span className="pricing-price block h1">{price}</span>
        {subPrice && (
          <div
            className="pricing-sub-price"
            style={{
              height:
                listState && listState.subPriceHeight
                  ? `${listState.subPriceHeight}px`
                  : "initial",
            }}
          >
            {subPrice.map((value, index) => (
              <span key={index} className="block">
                {value}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={classNames("pricing-body", bodyClasses)}>{children}</div>
    </div>
  );
};
