import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import { PricingList, PricingListItem } from '../components/pricingList';

const BoardingRates = () => {
  var pageTitle = 'Boarding Rates';

  return (
    <Layout
      slug="rates"
      title={pageTitle}
      description="Learn more about the variety of options and our rates for pet boarding!"
    >
      <h1>{pageTitle}</h1>
      <h2 className="h1 text-center">Dogs</h2>
      <span className="block text-center mb-4">
        Please inquire for monthly and weekly rates
        <p classname="font-bold">
          Please note these prices include pickup before noon
        </p>
      </span>
      <PricingList listClasses="mb-6">
        <PricingListItem
          title="Standard Suite"
          price="$34/day"
          subPrice={['Additional Pets - $19/day']}
          itemClasses="bg-vip-passion"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Daily access to XL play yards
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Air conditioned and heated<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>80-86 sq. ft. of private indoor/outdoor access</li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates - $20/day - each additional pet is $12/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
        <PricingListItem
          title="Large Dog Suite"
          price="$38/night"
          subPrice={['Additional Pets - $20/day']}
          itemClasses="bg-vip-purple"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Includes all amenities from Standard Suite
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              210 sq. ft. of private indoor/outdoor access
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Daily access to XL play yard </li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates - $25/day - each additional pet is $14/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
        <PricingListItem
          title="Family Suite"
          price="$40/night"
          subPrice={['Additional Pets - $21/day']}
          itemClasses="bg-vip-coral"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Includes all amenities from Large Dog Suite
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              225 sq. ft. of private indoor/outdoor access
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Toddler bed
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Daily access to XL play yard </li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates - $25/day - each additional pet is $14/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
      </PricingList>

      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Small Dog Luxury Suite"
          price="$36/night"
          subPrice={['Additional Pets - $19/day']}
          itemClasses="bg-vip-coral"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Includes all amenities from Family/Large Suites
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Fully private room<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              80 sq. ft. of private indoor/outdoor access
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Additional amenities including a miniature leather couch.
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Daily access to XL play yard </li>
          </ul>
        </PricingListItem>

        <PricingListItem
          title="Luxury Suite"
          price="$48/day"
          subPrice={['Additional Pets- $23/day']}
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Includes all amenities from Family/Large Suites
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Fully private room<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              500 sq. ft. of private indoor/outdoor access
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Additional amenities including a toddler bed and fireplace
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Daily access to XL play yard </li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates - $33/day - each additional pet is $16/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
        <PricingListItem
          title="Pawsh Suite"
          price="$58/day"
          subPrice={['Additional Pets - $32/day']}
          itemClasses="bg-vip-passion"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses=" text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Includes all amenities from Luxury Suite
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Fully private room and xl outdoor play yard with play structures
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Over 1,200 sq. ft. of private indoor/outdoor space.
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Additional amenities including a toddler bed, leather couch and
              fireplace
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Private XL play yard</li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates - $38/day - each additional pet is $18/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
      </PricingList>
      <h2 className="h1 text-center">Dog Boarding Add Ons</h2>
      <span id="boarding-add-ons" className="block text-center mb-4">
        Please note that boarding add ons are subject to availability
      </span>
      <div className="">
        <div className="flex w-1/2 mx-auto">
          <div>Full enrichment package</div>
          <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
          <div>$30/day</div>
        </div>
        <p>
          Meals will be provided in a maze or treat ball toy, a puzzle time
          session is included as well as 2 - 20 minute sessions of one on one
          enrichment activities with a staff member such as a sniffy walk,
          agility course time, hide n’ seek etc.
        </p>
        <div className="flex w-1/2 mx-auto">
          <div>Mini enrichment package</div>
          <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
          <div>$20/day</div>
        </div>
        <p>
          Meals will be provided in a maze or treat ball toy, a puzzle time
          session is included as well as a 20 minute sessions of one on one
          enrichment activities with a staff member such as a sniffy walk,
          agility course time, hide n’ seek etc.
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Tuck in package</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$15/night</div>
          </div>
          <p>
            This package includes a pupsicle, chew or treat of the month as a
            bedtime treat, a 15 minute cuddle session, and a bed/cot upgrade for
            standard suites. A daily picture update through our app will be
            included.
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Agility session</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$15/session</div>
          </div>
          <p>
            (Available for dogs over 6 months of age) This is a supervised
            expedition through our agility play yard. The agility play yard
            includes jumps, tunnels, teeter totters, an A-frame and more! This
            is a great opportunity for the pups who need a little extra exercise
            and mental stimulation.
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Sniffy walk</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$12/session</div>
          </div>
          <p>
            This is a pet led walk geared to your dog’s preferences. For some
            that may mean a jog in the field while other pets may prefer slowly
            meandering around the countryside.
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Hide N' Seek</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$5/session</div>
          </div>
          <p>
            A great game of Hide N’ Seek with your pet. This includes treats
            hidden around their room and around their yard. This creates a great
            game and allows your pup to use their nose in a mentally stimulating
            way.
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Puzzle time</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 mb-2"></div>
            <div>$5/session</div>
          </div>
          <p>
            A snack will be provided in one of our large variety of fun,
            stimulating puzzle toys. Different difficulties available, great for
            beginners and experts!
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Stuffed Kong</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$5/Lg $3/Sm</div>
          </div>
          <p>
            A Kong stuffed with goodies is given for a relaxing bedtime snack.
            Ask us about the flavour of the month!
          </p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Chew</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$3/session</div>
          </div>
          <p>A great tasting chew to keep your pup occupied</p>
        </p>
        <p>
          <div className="flex w-1/2 mx-auto">
            <div>Pupsicle</div>
            <div className="border-dotted border-black border-b-2 flex-grow mx-3 "></div>
            <div>$3</div>
          </div>
          <p>
            A frozen treat, perfect for staying cool in the summertime! Ask us
            about the flavour of the month!
          </p>
        </p>
      </div>
      <h2 className="h1 text-center">Cats</h2>
      <span className="block text-center mb-4">
        Please inquire for monthly and weekly rates
        <p classname="font-bold">
          Please note these prices include pickup before noon
        </p>
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Standard Suite"
          price="$19/night"
          subPrice={['2nd and more - $15/day']}
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses=" text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Private rooms<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Fully optional, private outdoor play yard with cat playground
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Window with ledge</li>
          </ul>
          {/* <h4>Special Rates</h4>
          <ul className="list-none pl-0 text-sm">
            <li>Monthly rates $15 per day - each additional pet is $10/day</li>
            <li>Multiple pet discounts apply when staying in the same suite</li>
          </ul> */}
        </PricingListItem>
      </PricingList>
      {/* <ul>
        <li>
          Arrival date - price includes drop off at any time within business
          hours
        </li>
        <li>Departure date - before noon - no additional charge</li>
        <li>12pm to 3pm - additional 1/2 day charge</li>
        <li>6pm to 8pm - additional full day charge</li>
      </ul> */}
      <p>
        <h2>Checkout Policy</h2>
        <p>
          Similar to a hotel we do have a checkout time, in an effort to provide
          a little extra time our checkout time is noon. Checkout before noon is
          free of charge however after noon checkout will incur additional
          charges.{' '}
        </p>
        <h2>Cancellation Policy</h2>
        <p>
          Our cancellation policy is flexible during the off season so please
          inquire. During the summer and on holidays we have a 1 week
          cancellation policy. We require a $50 deposit to confirm a reservation
          at this time. It is completely refundable if the cancellation is made
          at least one week before the start of the reservation.
        </p>
        <b>Note:</b> If you arrive outside of business hours without an
        appointment there is an additional $40 fee. For an additional $12 fee
        you may request an appointment to arrive outside of business hours. You
        can find our updated business hours on our{' '}
        <Link to="/contact/">contact page</Link>.
      </p>
      <p>
        Please contact us for special pricing for extended stays (more than a
        month or consistent days per month) due to work or medical needs.
      </p>
      <p>
        <b>Note:</b> We can board females that are in heat however there is an
        additional $10/day fee up to $50 due to the additional management and
        care required.
      </p>

      <h2>Cancellation Policy</h2>
      <p>
        Our cancellation policy is flexible during the off season so please
        inquire. During the summer and on holidays we have a 1 week cancellation
        policy. We require a 25% deposit to confirm a reservation at this time.
        It is completely refundable if the cancellation is made at least one
        week before the start of the reservation.
      </p>
    </Layout>
  );
};

export default BoardingRates;
